/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:5a95c54b-d8eb-455f-9d18-a89cd27d14b3',

    // (required)- Amazon Cognito Region
    region: 'us-west-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_zuIOlVB0t',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '4le5kginfteho5ed2b360rvv2i',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'meta-dimension-access-control-beta' + '.auth.us-west-2.amazoncognito.com',
      redirectSignIn: 'https://' + window.location.host + '/',
      redirectSignOut: 'https://' + window.location.host + '/',
      scope: ['openid'],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'Service',
        endpoint: '',
      },
    ],
  },
};
