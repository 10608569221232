import TopNavigation from '@cloudscape-design/components/top-navigation';
import React from 'react';
import { useTranslation, useUserAlias } from 'src/common/hooks';
import { BUNDLE_NAME } from 'src/constants';

/**
 * React component to render common top navigation
 */
export const NavBar = () => {
  const { alias } = useUserAlias();
  const { t } = useTranslation(BUNDLE_NAME.Common);

  return (
    <TopNavigation
      data-testid="navbar"
      identity={{ href: '/', title: t('title') }}
      utilities={[
        {
          type: 'button',
          text: alias,
          iconName: 'user-profile',
          href: `https://phonetool.amazon.com/users/${alias}`,
          target: '_blank',
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
        overflowMenuTitleText: 'All',
        overflowMenuBackIconAriaLabel: 'Back',
        overflowMenuDismissIconAriaLabel: 'Close menu',
      }}
    />
  );
};
