import {
  Box,
  Button,
  FormField,
  Input,
  SpaceBetween,
  SplitPanel,
} from '@cloudscape-design/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DimensionDetails,
  DimensionsApi,
  Element,
} from 'src/api/dimensionsAPi';
import { useSplitsPanelContext } from 'src/common/provider/SplitPanelProvider';

interface ElementProps {
  dimensionDetails: DimensionDetails;
  element?: Element;
  index?: number;
}

export const ConfigureElement: FC<ElementProps> = (props) => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const queryClient = useQueryClient();
  const { onSplitPanelToggle } = useSplitsPanelContext();

  useEffect(() => {
    setName(props.element?.name ?? '');
  }, [props.element?.name]);

  const updateMutation = useMutation({
    mutationFn: DimensionsApi.updateDimensionDetails,
    onSuccess: () => {
      queryClient.invalidateQueries(['dimension', 'details', id]);
      onSplitPanelToggle();
    },
  });

  const onApply = () => {
    updateMutation.mutate({
      id: id ?? '',
      dimensionDetails: {
        ...props.dimensionDetails,
        leafElements:
          props.index === undefined
            ? [...props.dimensionDetails.leafElements, { name }]
            : props.dimensionDetails.leafElements.map((el, i) =>
                i === props.index ? { ...el, name } : el,
              ),
      },
    });
  };

  return (
    <SplitPanel
      header={props.element?.name ? 'Edit element' : 'Add element'}
      closeBehavior="hide"
      i18nStrings={{
        closeButtonAriaLabel: 'Close',
        openButtonAriaLabel: 'Open',
        preferencesCancel: 'Cancel',
        preferencesConfirm: 'Confirm',
        preferencesPositionBottom: 'Bottom',
        preferencesPositionDescription:
          'Choose the default split panel position',
        preferencesPositionLabel: 'Split panel position',
        preferencesPositionSide: 'Side',
        preferencesTitle: 'Split panel preferences',
        resizeHandleAriaLabel: 'resize',
      }}
    >
      <SpaceBetween size="s">
        <FormField label="Name" stretch>
          <Input value={name} onChange={(e) => setName(e.detail.value)} />
        </FormField>
        <Box float="right">
          <Button variant="primary" onClick={onApply}>
            Apply
          </Button>
        </Box>
      </SpaceBetween>
    </SplitPanel>
  );
};
