import Spinner from '@cloudscape-design/components/spinner';
import AppLayout from '@cloudscape-design/components/app-layout';
import { useAuthenticator } from '@aws-amplify/ui-react';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavBar } from './Navbar';
import { Breadcrumbs } from './Breadcrumbs';
import { useSplitsPanelContext } from 'src/common/provider/SplitPanelProvider';
import { Notification } from 'src/common/components/Notification';

export const Layout = (): JSX.Element => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const { splitPanelOpen, onSplitPanelToggle, splitPanelContent } =
    useSplitsPanelContext();

  return (
    <>
      {route === 'authenticated' && (
        <>
          <NavBar />
          <AppLayout
            disableContentPaddings={isLandingPage}
            breadcrumbs={isLandingPage ? undefined : <Breadcrumbs />}
            content={<Outlet />}
            toolsHide={true}
            navigationHide={true}
            maxContentWidth={Number.MAX_VALUE}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={onSplitPanelToggle}
            splitPanel={splitPanelContent}
            notifications={<Notification />}
            contentType="default"
          />
        </>
      )}
      {route !== 'authenticated' && <Spinner data-testid="page-loader" />}
    </>
  );
};

export default Layout;
