import { API, Auth } from 'aws-amplify';

/**
 * This method will retrieve JWT token of current session.
 */
const getHeaders = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
});

export class Api {
  apiName: string;
  constructor(apiName: string) {
    this.apiName = apiName;
  }

  /**
   * This method will create data.
   */
  async post(path: string, payload: any) {
    return API.post(this.apiName, path, {
      headers: await getHeaders(),
      body: payload,
    })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  /**
   * This method will retrive data.
   */
  async get(path: string, queryStringParameters?: any) {
    return API.get(this.apiName, path, {
      headers: await getHeaders(),
      queryStringParameters,
    })
      .then((response) => response)
      .catch((error) => {
        throw new Error(
          error.response?.data?.message || error.response.message,
        );
      });
  }

  /**
   * This method will update data.
   */
  async put(path: string, payload: any) {
    return API.put(this.apiName, path, {
      headers: await getHeaders(),
      body: payload,
    })
      .then((response) => response)
      .catch((error) => {
        throw new Error(error.response.data.message);
      });
  }

  /**
   * This method will delete data.
   */
  async delete(path: string, payload: any) {
    return API.del(this.apiName, path, {
      headers: await getHeaders(),
      body: payload,
    });
  }
}
