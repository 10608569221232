import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface SplitPanelProps {
  title: string;
  content: string;
  links: { href: string; text: string }[];
}

export const useSplitPanel = () => {
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);
  const [splitPanelContent, setSplitPanelContent] = useState<ReactNode>();

  const onSplitPanelToggle = useCallback(() => {
    setSplitPanelOpen(!splitPanelOpen);
  }, [splitPanelOpen]);

  const onSplitPanelContentChange = useCallback((content: ReactNode) => {
    setSplitPanelContent(content);
  }, []);

  return {
    splitPanelOpen,
    onSplitPanelToggle,
    splitPanelContent,
    onSplitPanelContentChange,
  };
};

interface SplitPanelContextType {
  splitPanelOpen: boolean;
  onSplitPanelToggle(): void;
  splitPanelContent?: React.ReactNode;
  onSplitPanelContentChange(content: React.ReactNode): void;
}

const SplitPanelContext = createContext<SplitPanelContextType>({
  splitPanelOpen: false,
  onSplitPanelToggle: () => {},
  splitPanelContent: undefined,
  onSplitPanelContentChange: () => {},
});

interface SplitPanelProviderProps {
  children: ReactNode;
}

export const SplitPanelProvider: React.FC<SplitPanelProviderProps> = ({
  children,
}) => {
  const splitPanel = useSplitPanel();

  return (
    <SplitPanelContext.Provider value={splitPanel}>
      {children}
    </SplitPanelContext.Provider>
  );
};

export const useSplitsPanelContext = () => useContext(SplitPanelContext);
