import { ColumnLayout, Container, Header } from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { DimensionsApi } from 'src/api/dimensionsAPi';
import { ValueWithLabel } from 'src/common/components/ValueWithLabel';
import { useTranslation } from 'src/common/hooks';
import { BUNDLE_NAME } from 'src/constants';

export const Summary: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation(BUNDLE_NAME.ConfigurePage);

  const summary = useQuery({
    queryKey: ['dimension', id],
    queryFn: () => DimensionsApi.getDimensionSummary(id ?? ''),
  });

  return (
    <Container header={<Header variant="h3">{t('summary_header')}</Header>}>
      <ColumnLayout columns={4}>
        <ValueWithLabel label={t('dimension_name')}>
          {summary.data?.name}
        </ValueWithLabel>
        <ValueWithLabel label={t('dimension_description')}>
          {summary.data?.description}
        </ValueWithLabel>
        <ValueWithLabel label={t('dimension_owner')}>
          {summary.data?.owner ? summary.data?.owner.value : '-'}
        </ValueWithLabel>
        <ValueWithLabel label={t('dimension_source')}>
          {summary.data?.source ? summary.data?.source.type : '-'}
        </ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};
