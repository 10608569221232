/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Table,
  Header,
  TextFilter,
  Pagination,
  CollectionPreferences,
  Box,
  Link,
  SpaceBetween,
  Button,
  Modal,
  Form,
  FormField,
  Input,
  Select,
  Textarea,
  LinkProps,
} from '@cloudscape-design/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { startTransition, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Dimension, DimensionsApi, SourceType } from 'src/api/dimensionsAPi';
import { useUserAlias } from 'src/common/hooks';

export const DimensionsPage = () => {
  const navigate = useNavigate();
  // get dimensions api
  const {
    data: getDimensionsResponse,
    refetch: getDimensionsTrigger,
    isFetching: isGetDimensionsFetching,
  } = useQuery({
    queryKey: ['dimensions'],
    queryFn: () => DimensionsApi.getDimensions(),
  });

  /**
   * This function utilizes useNavigate hook to perform navigation.
   */
  const handleNavigation = (event: CustomEvent<LinkProps.FollowDetail>) => {
    event.preventDefault();
    startTransition(() => navigate(event.detail.href as string));
  };

  // dimension table setting
  const columnDefinitions = [
    {
      id: 'name',
      header: 'Dimension name',
      cell: (item: Dimension) => (
        <Link
          href={`/configure/${item.dimensionId}`}
          onFollow={handleNavigation}
        >
          {item.name}
        </Link>
      ),
    },
    {
      id: 'description',
      header: 'Description',
      cell: (item: Dimension) => item.description,
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item: Dimension) => (item.source ? item.source.type : '-'),
    },
    {
      id: 'version',
      header: 'Version',
      cell: (item: Dimension) => item.version,
    },
    {
      id: 'owner',
      header: 'Owner',
      cell: (item: Dimension) => (item.owner ? item.owner.value : '-'),
    },
    {
      id: 'hierarchies',
      header: 'Hierarchies',
      cell: (item: Dimension) =>
        item.hierarchies
          ? item.hierarchies.map((item) => item.name).join(', ')
          : '-',
    },
    {
      id: 'lastUpdatedBy',
      header: 'Last Updated By',
      cell: (item: Dimension) => item.lastUpdatedBy,
    },
    {
      id: 'lastUpdatedOn',
      header: 'Last Updated On',
      cell: (item: Dimension) =>
        item.lastUpdatedOn
          ? new Date(item.lastUpdatedOn * 1000).toLocaleString('en-US')
          : '-',
      sortingField: 'lastUpdatedOn',
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item: Dimension) =>
        item.updateStatus ? item.updateStatus.state : '-',
    },
  ];

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    visibleContent: [
      'name',
      'description',
      'type',
      'version',
      'owner',
      'hierarchies',
      'subscriber',
      'lastUpdatedBy',
      'lastUpdatedOn',
      'status',
    ],
  });

  const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page',
  };

  const collectionPreferencesProps = {
    pageSizePreference: {
      title: 'Select page size',
      options: [
        { value: 10, label: '10 items' },
        { value: 20, label: '20 items' },
      ],
    },
    visibleContentPreference: {
      title: 'Select visible content',
      options: [
        {
          label: 'Main properties',
          options: columnDefinitions.map(({ id, header }) => ({
            id,
            label: header,
            editable: id !== 'id',
          })),
        },
      ],
    },
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    title: 'Preferences',
  };

  const {
    items,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(
    getDimensionsResponse?.dimensions ? getDimensionsResponse.dimensions : [],
    {
      filtering: {
        empty: (
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <b>No item</b>
          </Box>
        ),
        noMatch: (
          <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
            <b>No match</b>
          </Box>
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      selection: {},
    },
  );
  const { selectedItems } = collectionProps;

  // create dimension modal
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  // create dimension api
  const {
    mutateAsync: createDimensionTrigger,
    isLoading: isCreateDimensionLoading,
  } = useMutation({
    mutationFn: (dimension: Dimension) =>
      DimensionsApi.createDimension(dimension),
  });

  const { alias } = useUserAlias();
  const onCreateModalSubmit = () => {
    createDimensionTrigger({
      name: formik.values.name,
      description: formik.values.description,
      source: {
        type: formik.values.type,
      },
      owner: {
        value: formik.values.owner,
      },
      version: '1.00',
      lastUpdatedBy: alias,
      lastUpdatedOn: Math.floor(Date.now() / 1000),
    }).then(() => {
      setShowCreateModal(false);
      getDimensionsTrigger();
    });
  };

  // create dimension form
  const formik = useFormik<CreateDimensionFormValues>({
    initialValues: {
      name: '',
      description: '',
      owner: '',
      type: SourceType.CustomConfiguration,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      owner: Yup.string().required('Required'),
      type: Yup.string().required('Required'),
    }),
    onSubmit: onCreateModalSubmit,
  });

  return (
    <>
      <br />
      <Table
        {...collectionProps}
        selectionType="single"
        header={
          <Header
            counter={`(${items.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                >
                  Create
                </Button>
                <Button
                  disabled={selectedItems!.length !== 1}
                  onClick={() =>
                    navigate(`/configure/${selectedItems?.[0].dimensionId}`)
                  }
                >
                  Edit
                </Button>
              </SpaceBetween>
            }
          >
            Dimensions
          </Header>
        }
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        items={items}
        loading={isGetDimensionsFetching || isCreateDimensionLoading}
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={
              filteredItemsCount === 1
                ? `1 match`
                : `${filteredItemsCount} matches`
            }
            filteringAriaLabel="Filter instances"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) =>
              setPreferences({
                pageSize: detail.pageSize!,
                visibleContent: detail.visibleContent as string[],
              })
            }
          />
        }
      />
      <Modal
        onDismiss={() => setShowCreateModal(false)}
        visible={showCreateModal}
        header="Create dimension"
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <Button variant="primary" onClick={formik.submitForm}>
                Submit
              </Button>
            }
          >
            <SpaceBetween direction="vertical" size="l">
              <FormField
                label="Name"
                errorText={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ''
                }
              >
                <Input
                  onChange={({ detail }) =>
                    formik.setFieldValue('name', detail.value)
                  }
                  value={formik.values.name}
                />
              </FormField>
              <FormField
                label="Owner"
                errorText={
                  formik.touched.owner && formik.errors.owner
                    ? formik.errors.owner
                    : ''
                }
              >
                <Input
                  onChange={({ detail }) =>
                    formik.setFieldValue('owner', detail.value)
                  }
                  value={formik.values.owner}
                />
              </FormField>
              <FormField
                label="Type"
                errorText={
                  formik.touched.type && formik.errors.type
                    ? formik.errors.type
                    : ''
                }
              >
                <Select
                  selectedOption={{
                    label: formik.values.type,
                    value: formik.values.type,
                  }}
                  onChange={({ detail }) =>
                    formik.setFieldValue('type', detail.selectedOption.value)
                  }
                  options={Object.values(SourceType).map((type) => ({
                    label: type,
                    value: type,
                  }))}
                />
              </FormField>
              <FormField
                label="Description"
                errorText={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : ''
                }
              >
                <Textarea
                  onChange={({ detail }) =>
                    formik.setFieldValue('description', detail.value)
                  }
                  value={formik.values.description}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
      </Modal>
    </>
  );
};

interface CreateDimensionFormValues {
  name: string;
  description: string;
  owner: string;
  type: SourceType;
}
