import React, { useEffect, useMemo } from 'react';
import Flashbar from '@cloudscape-design/components/flashbar';
import { useLocation } from 'react-router-dom';
import { useNotificationContext } from '../provider/NotificationProvider';

/**
 * Common react component for Notifications
 */
export const Notification = () => {
  const location = useLocation();

  const { notifications, clearNotification } = useNotificationContext();

  const messages = useMemo(
    () =>
      notifications.map((notification) => ({
        dismissible: true,
        onDismiss: clearNotification,
        ...notification,
      })),
    [clearNotification, notifications],
  );

  const items = useMemo(() => {
    if (messages.length) {
      return messages;
    }
    return [];
  }, [messages]);

  useEffect(() => {
    clearNotification();
  }, [clearNotification, location.pathname]);

  return (
    <div style={{ position: 'absolute', top: 0, zIndex: 1005, width: '100%' }}>
      {items.length ? (
        <Flashbar data-testid="notification-panel" items={items} />
      ) : (
        <></>
      )}
    </div>
  );
};
