import React from 'react';
import { RouteObject } from 'react-router-dom';
import { Layout } from './layout';
import { ConfigurePage } from './pages/configure-page/ConfigurePage';
import { LandingPage } from './pages/landing-page/LandingPage';
import { DimensionsPage } from './pages/dimensions-page/DimensionsPage';

// App routes
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'configure/:id',
        element: <ConfigurePage />,
      },
      {
        path: 'dimensions',
        element: <DimensionsPage />,
      },
    ],
  },
];
