import React, { FC, useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { Element } from 'src/api/dimensionsAPi';

interface DragElementProps {
  element: Element;
  onClick: () => void;
}

export const DragElement: FC<DragElementProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [, drop] = useDrop({
    accept: 'externalNode',
  });

  const [, drag] = useDrag({
    type: 'externalNode',
    item: { node: { title: props.element.name } },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    options: {
      dropEffect: 'copy',
    },
  });

  drag(drop(ref));

  return (
    <button
      ref={ref}
      onClick={props.onClick}
      style={{
        background: 'white',
        width: '100%',
        height: '100%',
        border: 'solid #bbb 1px',
        boxShadow: '2px 2px 2px -2px',
        borderRadius: '2px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        padding: '5px',
        fontWeight: 'bold',
      }}
    >
      {props.element.name}
    </button>
  );
};
