import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface DragAndDropProps {
  id: string;
  children: React.ReactNode;
}

export const DragAndDrop: FC<DragAndDropProps> = (props) => (
  <DndProvider
    backend={HTML5Backend}
    options={{ rootElement: document.getElementById(props.id) }}
  >
    {props.children}
  </DndProvider>
);
