import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

interface NotificationContextType {
  notifications: FlashbarProps.MessageDefinition[];
  addNotification(notification: FlashbarProps.MessageDefinition): void;
  clearNotification(): void;
}

/**
 * React context for Notifications
 */
const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  addNotification: () => {},
  clearNotification: () => {},
});

/**
 * Props for NotificationProvider
 */
interface NotificationProviderProps {
  children: ReactNode;
}

/**
 * Wrapper to allow notifications in application
 */
export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const addNotification = useCallback(
    (message: FlashbarProps.MessageDefinition) => {
      setNotifications((notifications) => [...notifications, message]);
    },
    [],
  );

  const clearNotification = useCallback(() => {
    setNotifications([]);
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        clearNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

/**
 * React hook to use notification context
 * @returns
 */
export const useNotificationContext = () => useContext(NotificationContext);
