import React, { FC, ReactNode } from 'react';
import Box from '@cloudscape-design/components/box';

/**
 * Props for ValueWithLabel component
 */
interface ValueWithLabelProps {
  label: string;
  children?: ReactNode;
}

/**
 * Common react component to display Key/Value pair
 */
export const ValueWithLabel: FC<ValueWithLabelProps> = ({
  label,
  children,
}) => (
  <>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </>
);
