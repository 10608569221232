import React, { useMemo } from 'react';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BUNDLE_NAME } from 'src/constants';
import { TFunction, useTranslation } from 'src/common/hooks';

const breadcrumbPathMappings = (
  t: TFunction,
  params: Readonly<Params<string>>,
): Record<string, BreadcrumbGroupProps.Item[]> => ({
  [`/configure/${params.id}`]: [
    { text: t('dimensions'), href: '/dimensions' },
    { text: params.id ?? '', href: '' },
  ],
  '/dimensions': [{ text: t('dimensions'), href: '' }],
});

/**
 * This components displays series of navigational links in a hierarchical list
 */
export const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation(BUNDLE_NAME.Common);
  const mappings = useMemo(
    () => breadcrumbPathMappings(t, params),
    [t, params],
  );

  const handleNavigation = (
    event: CustomEvent<BreadcrumbGroupProps.ClickDetail>,
  ) => {
    event.preventDefault();
    navigate(event.detail.href as string);
  };

  const paths: BreadcrumbGroupProps.Item[] = [
    {
      text: t('home'),
      href: '/',
    },
  ];

  const pathKey = Object.keys(mappings).find((path) =>
    location.pathname.includes(path),
  );

  return (
    <BreadcrumbGroup
      items={pathKey ? paths.concat(mappings[pathKey]) : []}
      onFollow={handleNavigation}
      ariaLabel="Breadcrumbs"
    />
  );
};
