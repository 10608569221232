import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MbmProvider } from '@amzn/react-arb-tools';
import arbManifest from 'translations/arbManifest';
import { LocalizationContextBuilder } from '@amzn/arb-tools';
import { AuthProvider } from './common/provider/AuthProvider';
import { routes } from './routes';
import { DEFAULT_LOCALE } from './constants';
import { resolveBundleUrlWithFallbacks } from './helpers';
import { SplitPanelProvider } from './common/provider/SplitPanelProvider';

import './logger';
import '@cloudscape-design/global-styles/index.css';
import './index.scss';
import { NotificationProvider } from './common/provider/NotificationProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5min
      refetchOnWindowFocus: false,
    },
  },
});

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: new LocalizationContextBuilder()
    .withLocale(DEFAULT_LOCALE)
    .withDefaultLocale(DEFAULT_LOCALE)
    .build(),
  resolveBundleUrl: (
    resourcePackPath: string | undefined,
    locale: string,
    bundle: string,
  ) => resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
};

// The Suspense component causes React to display the fallback Spinner until
// the i18n translation strings have been loaded.
const container = document.getElementById('root');
const root = createRoot(container!);

const prepareMsw = async () => {
  // Note : Enabling MSW in production mode.
  // We can disable this once API are available for all the environments.
  // if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW) {
  const { worker } = await import('test/server/browser');
  await worker.start();
  // }
  // return Promise.resolve();
};

prepareMsw().then(() => {
  root.render(
    <React.StrictMode>
      <MbmProvider {...mbmOptions}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationProvider>
              <SplitPanelProvider>
                <RouterProvider router={createBrowserRouter(routes)} />
              </SplitPanelProvider>
            </NotificationProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </MbmProvider>
    </React.StrictMode>,
  );
});
