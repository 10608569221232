import { useBundle } from '@amzn/react-arb-tools';
import { t } from 'src/helpers';

export type TFunction = (key: string) => string;

export const useTranslation = (page: string): { t: TFunction } => {
  const [bundle] = useBundle(page);
  return {
    t: (key: string) => t(bundle, key) ?? '',
  };
};
