import { Api } from './Api';

/**
 * This function will substitute the path variable with the actual value
 * @param path API Path
 * @param params Map of Path Variable and Value
 * @returns Path with actual value of path variable.
 * @example
 * generatePath('/booking/:bookingId/update', {
 *   bookingId: 'XXX',
 * }
 */
export const generatePath = (
  path: string,
  params?: Record<string, string | undefined>,
) => {
  if (!params) {
    return path;
  }
  const pathParams = Object.entries(params).reduce(
    (acc, [key, value]) => acc.replace(`:${key}`, value ?? ''),
    path,
  );
  return pathParams;
};

export enum SourceType {
  SystemDimension = 'System Dimension',
  CustomConfiguration = 'Custom Configuration',
}

export enum OwnerType {
  Alias = 'alias',
  Team = 'team',
  Bindle = 'bindle',
}

export interface Element {
  name: string;
  attributeValueList?: string[];
  weight?: string;
  children?: Element[];
}

export interface HierarchyStructure {
  title: string;
  value: string;
  children?: HierarchyStructure[];
}

export interface Hierarchy {
  hierarchyName: string;
  consolidatedElements: Element[];
  hierarchicalStructure: HierarchyStructure[];
}

// Types
export interface Dimension {
  dimensionId?: string;
  name?: string;
  description?: string;
  source?: {
    type: SourceType;
    link?: string;
  };
  version?: string;
  owner?: {
    type?: OwnerType;
    value: string;
  };
  hierarchies?: {
    name: string;
    description: string;
    owner: {
      type: OwnerType;
      value: string;
    };
  }[];
  attributeMap?: Record<string, string>;
  lastUpdatedBy?: string;
  lastUpdatedOn?: number;
  updateStatus?: { state: string };
}

export interface DimensionDetails {
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  dimensionId: string;
  attributeList: string[];
  leafElements: Element[];
  hierarchies: Hierarchy[];
}

export interface GetDimensionsResponse {
  dimensions: Dimension[];
}

/**
 * Stores the path of API's
 */
export const PATH: Record<string, string> = {
  getDimensions: '/v1/dimensions',
  getDimensionSummary: '/v1/dimensions/:dimensionId/general-information',
  getDimensionDetails: '/v1/dimensions/:dimensionId/details',
  createDimension: '/v1/dimensions',
  updateDimensionDetails: '/v1/dimensions/:dimensionId/details',
};

/**
 * Create instance of API for service
 */
const API = new Api('Service');

/**
 * This object implements all methods presents in service
 */
export const DimensionsApi = {
  getDimensions(): Promise<GetDimensionsResponse> {
    return API.get(PATH.getDimensions);
  },
  getDimensionSummary(id: string): Promise<Dimension> {
    const path = generatePath(PATH.getDimensionSummary, {
      dimensionId: id,
    });
    return API.get(path);
  },
  getDimensionDetails(id: string): Promise<DimensionDetails> {
    const path = generatePath(PATH.getDimensionDetails, {
      dimensionId: id,
    });
    return API.get(path);
  },
  createDimension(dimension: Dimension): Promise<Dimension> {
    return API.post(PATH.createDimension, dimension);
  },
  updateDimensionDetails(request: {
    id: string;
    dimensionDetails: DimensionDetails;
  }): Promise<void> {
    const path = generatePath(PATH.updateDimensionDetails, {
      dimensionId: request.id,
    });
    return API.put(path, request.dimensionDetails);
  },
};
