import {
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Select,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useQuery } from '@tanstack/react-query';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import SortableTree from '@nosferatu500/react-sortable-tree';
import { ConfigureElement } from './ConfigureElement';
import { ConfigureHierarchy } from './ConfigureHierarchy';
import { ConfigureGroupElement } from './ConfigureGroupElement';
import { DragElement } from './DragElement';
import {
  DimensionsApi,
  Element,
  HierarchyStructure,
} from 'src/api/dimensionsAPi';
import { useTranslation } from 'src/common/hooks';
import { useSplitsPanelContext } from 'src/common/provider/SplitPanelProvider';
import { BUNDLE_NAME } from 'src/constants';

interface TreeData {
  title: string;
  expanded: boolean;
  children: TreeData[];
}

const createTreeData = (hierarchyStructure: HierarchyStructure[]): TreeData[] =>
  hierarchyStructure.map((structure: HierarchyStructure) => ({
    title: structure.title,
    expanded: false,
    children: structure.children ? createTreeData(structure.children) : [],
  })) ?? [];

export const Details = () => {
  const { t } = useTranslation(BUNDLE_NAME.ConfigurePage);
  const { splitPanelOpen, onSplitPanelContentChange, onSplitPanelToggle } =
    useSplitsPanelContext();
  const [selectedHierarchy, setSelectedHierarchy] = useState<string>();
  const [groupElements, setGroupElements] = useState<Element[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const { id } = useParams();

  const { data: details } = useQuery({
    queryKey: ['dimension', 'details', id],
    queryFn: () => DimensionsApi.getDimensionDetails(id ?? ''),
  });

  const hierarchyOptions = useMemo(
    () =>
      details?.hierarchies.map(
        (hierarchy) =>
          ({
            label: hierarchy.hierarchyName,
            value: hierarchy.hierarchyName,
          } ?? []),
      ),
    [details?.hierarchies],
  );

  useEffect(() => {
    if (!details?.hierarchies) {
      return;
    }
    const selectedHierarchyIndex = selectedHierarchy
      ? details.hierarchies.findIndex(
          (hierarchy) => hierarchy.hierarchyName === selectedHierarchy,
        )
      : 0;
    setSelectedHierarchy(
      details.hierarchies[selectedHierarchyIndex]?.hierarchyName ?? '',
    );
    setTreeData(
      createTreeData(
        details.hierarchies[selectedHierarchyIndex]?.hierarchicalStructure ??
          [],
      ),
    );
    setGroupElements(
      details.hierarchies[selectedHierarchyIndex]?.consolidatedElements ?? [],
    );
  }, [details, selectedHierarchy]);

  const getSplitPanelContent = useCallback(
    (
      type: 'Element' | 'Hierarchy' | 'GroupElement',
      index?: number,
      element?: Element,
    ) => {
      if (!details) {
        return;
      }
      switch (type) {
        case 'Element':
          return (
            <ConfigureElement
              dimensionDetails={details}
              index={index}
              element={element}
            />
          );
        case 'Hierarchy':
          return <ConfigureHierarchy dimensionDetails={details} />;
        case 'GroupElement':
          return (
            <ConfigureGroupElement
              dimensionDetails={details}
              hierarchyName={selectedHierarchy ?? ''}
              element={element}
              index={index}
            />
          );
      }
    },
    [details, selectedHierarchy],
  );

  const onElementClick = useCallback(
    (
      type: 'Element' | 'Hierarchy' | 'GroupElement',
      index?: number,
      element?: Element,
    ) => {
      if (!details) {
        return;
      }
      const content = getSplitPanelContent(type, index, element);
      onSplitPanelContentChange(content);
      if (!splitPanelOpen) {
        onSplitPanelToggle();
      }
    },
    [
      details,
      getSplitPanelContent,
      onSplitPanelContentChange,
      onSplitPanelToggle,
      splitPanelOpen,
    ],
  );

  return (
    <>
      <Header variant="h3">{t('details_header')}</Header>
      <Grid
        gridDefinition={[
          { colspan: { default: 4 } },
          { colspan: { default: 8 } },
        ]}
      >
        <ColumnLayout columns={1}>
          <Container
            variant="stacked"
            header={
              <Header
                variant="h3"
                actions={
                  <Button
                    variant="icon"
                    iconName="add-plus"
                    onClick={() => onElementClick('Element')}
                  />
                }
              >
                Leaf Elements
              </Header>
            }
            fitHeight
          >
            <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
              <SpaceBetween size="xs">
                {details?.leafElements.map((element, index) => (
                  <Fragment key={index}>
                    <DragElement
                      element={element}
                      onClick={() => {
                        onElementClick('Element', index, element);
                      }}
                    />
                  </Fragment>
                ))}
              </SpaceBetween>
            </div>
          </Container>
          <Container
            variant="stacked"
            header={
              <Header
                variant="h3"
                actions={
                  <Button
                    variant="icon"
                    iconName="add-plus"
                    onClick={() => onElementClick('GroupElement')}
                  />
                }
              >
                Group Elements
              </Header>
            }
            fitHeight
          >
            <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
              <SpaceBetween size="xs">
                {groupElements.map((element, index) => (
                  <Fragment key={index}>
                    <DragElement
                      element={element}
                      onClick={() => {
                        onElementClick('GroupElement', index, element);
                      }}
                    />
                  </Fragment>
                ))}
              </SpaceBetween>
            </div>
          </Container>
        </ColumnLayout>
        <Container
          variant="stacked"
          header={
            <Header
              actions={
                <SpaceBetween size="xs" direction="horizontal">
                  <Select
                    options={hierarchyOptions}
                    selectedOption={{
                      label: selectedHierarchy,
                      value: selectedHierarchy,
                    }}
                    onChange={(e) =>
                      setSelectedHierarchy(e.detail.selectedOption.value)
                    }
                  />
                  <Button
                    variant="icon"
                    iconName="add-plus"
                    onClick={() => onElementClick('Hierarchy')}
                  />
                </SpaceBetween>
              }
              variant="h3"
            >
              Hierarchy Mapping
            </Header>
          }
          fitHeight
        >
          <SortableTree
            treeData={treeData}
            dndType="externalNode"
            onChange={(data) => setTreeData(data)}
          />
        </Container>
      </Grid>
    </>
  );
};
