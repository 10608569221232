import React, { startTransition } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Link, { LinkProps } from '@cloudscape-design/components/link';
import { useNavigate } from 'react-router-dom';
import Box from '@cloudscape-design/components/box';
import Grid from '@cloudscape-design/components/grid';
import Container from '@cloudscape-design/components/container';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import './landing-page.scss';
import { Header } from '@cloudscape-design/components';
import { useTranslation } from 'src/common/hooks';
import { BUNDLE_NAME } from 'src/constants';

/**
 * This component renders the landing page
 */
export const LandingPage = () => {
  const { t } = useTranslation(BUNDLE_NAME.LandingPage);
  const navigate = useNavigate();

  /**
   * This function utilizes useNavigate hook to perform navigation.
   */
  const handleNavigation = (event: CustomEvent<LinkProps.FollowDetail>) => {
    event.preventDefault();
    startTransition(() => navigate(event.detail.href as string));
  };

  return (
    <Box margin={{ bottom: 'l' }} data-testid="landing-page">
      <div className="landing-page-header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 1, xxs: 1 }, colspan: { l: 10, xxs: 11 } },
              { offset: { l: 1, xxs: 1 }, colspan: { l: 10, xxs: 11 } },
            ]}
          >
            <div className="title">
              <Box
                variant="h1"
                fontWeight="heavy"
                padding="n"
                fontSize="display-l"
                color="inherit"
              >
                {t('title')}
              </Box>
              <ColumnLayout columns={2}>
                <Box variant="p">
                  <span className="sub-title">{t('description')}</span>
                </Box>
              </ColumnLayout>
            </div>
          </Grid>
        </Box>
      </div>
      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            {
              colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
              offset: { l: 2, xxs: 1 },
            },
            {
              colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
              offset: { s: 0, xxs: 1 },
            },
          ]}
        >
          <SpaceBetween size="xxl">
            <div>
              <Box
                variant="h1"
                tagOverride="h2"
                padding={{ bottom: 's', top: 'n' }}
                fontWeight="light"
              >
                Why is it important ?
              </Box>
              <Container>
                <SpaceBetween size="s">
                  <ColumnLayout columns={2} variant="text-grid">
                    <div>
                      <Box fontWeight="bold">Custom WWASF Dimensions</Box>
                      <Box variant="p">
                        Trig will enable definition and management of custom
                        WWASF Dimensions and creation/management of standard
                        hierarchy for these custom WWASF dimensions
                      </Box>
                    </div>
                    <div>
                      <Box fontWeight="bold">Create/Manage hierarchies</Box>
                      <Box variant="p">
                        Trig will enable creation/management of alternate
                        hierarchies for custom WWASF dimensions by allowing
                        rolling up dimension elements by customers to cater to
                        business specific usecases
                      </Box>
                    </div>
                    <div>
                      <Box fontWeight="bold">Governance</Box>
                      <Box variant="p">
                        Trig will be governed according to the roles and
                        responsibilities defined by an HierarchEase governance
                        program, which will be integrated into approval and
                        notification features.
                      </Box>
                    </div>
                    <div>
                      <Box fontWeight="bold">Data Layer</Box>
                      <Box variant="p">
                        Trig will enable other WWASF Products to get WWASF
                        Custom dimension and all related Hierarchy information
                        via a data layer.
                      </Box>
                    </div>
                  </ColumnLayout>
                </SpaceBetween>
              </Container>
            </div>
          </SpaceBetween>
          <div className="side-content">
            <Container header={<Header variant="h2">Getting Started</Header>}>
              <ColumnLayout borders="horizontal" columns={1}>
                <Link href="/dimensions" onFollow={handleNavigation}>
                  View Dimensions
                </Link>
                <Link href="/dimensions" onFollow={handleNavigation}>
                  Create Dimensions
                </Link>
              </ColumnLayout>
            </Container>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default LandingPage;
