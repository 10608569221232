import {
  Button,
  ContentLayout,
  Header,
  SpaceBetween,
} from '@cloudscape-design/components';
import React from 'react';
import { Summary } from './summary-section/Summary';
import { Details } from './details-section/Details';
import { useTranslation } from 'src/common/hooks/useTranslation';
import { BUNDLE_NAME } from 'src/constants';
import { DragAndDrop } from 'src/common/components/DragAndDrop';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';

export const ConfigurePage = () => {
  const { t } = useTranslation(BUNDLE_NAME.ConfigurePage);
  const { addNotification } = useNotificationContext();

  return (
    <ContentLayout
      header={
        <Header
          actions={
            <Button
              variant="primary"
              onClick={() => {
                addNotification({
                  type: 'success',
                  dismissible: true,
                  content: 'Hierarchy mapping saved successfully.',
                });
              }}
            >
              {t('save')}
            </Button>
          }
        >
          {t('header')}
        </Header>
      }
    >
      <SpaceBetween size="xs">
        <Summary />
        <div id="details">
          <DragAndDrop id="details">
            <Details />
          </DragAndDrop>
        </div>
      </SpaceBetween>
    </ContentLayout>
  );
};
