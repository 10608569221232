var endpointMap = {
	en: {
		"pages.common": "translation-en.json",
		"pages.configure": "translation-en.json",
		"pages.landing": "translation-en.json"
	},
	"en-US": {
		"pages.common": "translation-en.json",
		"pages.configure": "translation-en.json",
		"pages.landing": "translation-en.json"
	},
	und: {
		"pages.common": "translation.json",
		"pages.configure": "translation.json",
		"pages.landing": "translation.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
